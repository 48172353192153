/* Top Navbar */
.navbar {
    background-color: #3cb6fd;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1000;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
        height: auto;
        flex-direction: column;
        padding: 10px;
    }
}

.navbar-logo {
    display: flex;
    align-items: center;
    gap: 10px;
    color: white;
    font-size: 1.5rem;
    text-decoration: none;
    font-family: Georgia, 'Times New Roman', Times, serif;
    transition: transform 0.3s ease, color 0.3s ease;

    @media (max-width: 768px) {
        margin-bottom: 10px;
    }
}

.navbar-logo-image {
    height: 40px;
    width: auto;
}

.navbar-logo:hover {
    transform: scale(1.1);
    color: #f8fcffbe;
}

.navbar-links {
    display: flex;
    list-style: none;
    gap: 30px;
    margin: 0;
    padding: 0;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 15px;
    }
}

.navbar-links li {
    display: inline;
}

.navbar-links a,
.navbar-links button {
    color: white;
    text-decoration: none;
    font-size: 1.1rem;
    font-weight: 300;
    letter-spacing: 0.05em;
    transition: color 0.3s ease-in-out;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;

    @media (max-width: 768px) {
        font-size: 1rem;
    }
}

.navbar-links a:hover,
.navbar-links button:hover {
    color: #f8fcffbe;
}


.loading-text {
    font-size: 1.2rem;
    color: #3498db;
    text-align: center;
    font-weight: 500;
    animation: pulse 1.5s infinite ease-in-out;
}

@keyframes pulse {
    0% {
        opacity: 0.6;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0.6;
    }
}

@media (max-width: 768px) {
    .navbar {
        padding: 10px;
        height: auto;
    }

    .navbar-links {
        flex-direction: column;
        width: 100%;
        gap: 10px;
    }

    .navbar-logo {
        margin-bottom: 10px;
        text-align: center;
    }
}

/* Add hamburger button styles */
.hamburger {
    display: none;
    flex-direction: column;
    justify-content: space-around;
    width: 30px;
    height: 25px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;
}

.hamburger span {
    width: 30px;
    height: 3px;
    background: white;
    border-radius: 10px;
    transition: all 0.3s linear;
}

@media (max-width: 768px) {
    .hamburger {
        display: flex;
    }

    .navbar {
        height: 80px;
        flex-direction: row;
        padding: 0 20px;
    }

    .navbar-links {
        display: none;
        position: absolute;
        top: 80px;
        left: 0;
        right: 0;
        flex-direction: column;
        background-color: #61b6e7;
        padding: 20px;
        gap: 20px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    .navbar-links.active {
        display: flex;
    }

    .navbar-logo {
        margin: 0;
    }
}

@media screen and (max-width: 768px) {
    .navbar-links .login-container {
        display: none !important;
    }
}