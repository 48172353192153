/* Help Page Container */
.help-container {
    max-width: 900px;
    margin: 40px auto;
    padding: 0 20px;
    min-height: calc(100vh - 400px);
}

.help-container h1 {
    color: #333;
    margin-bottom: 40px;
    text-align: center;
}

/* Help Sections Grid */
.help-sections {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/* Individual Help Section Cards */
.help-section {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.section-header {
    padding: 20px;
    margin: 0;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f8f9fa;
    transition: background-color 0.3s ease;
}

.section-header:hover {
    background: #e9ecef;
}

.arrow {
    font-size: 12px;
    transition: transform 0.3s ease;
}

.arrow.expanded {
    transform: rotate(180deg);
}

.section-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
}

.section-content.expanded {
    max-height: 2000px;
    /* Adjust based on content */
}

.help-item {
    padding: 20px;
    border-top: 1px solid #eee;
}

.help-item h3 {
    color: #0078af;
    margin-bottom: 10px;
    font-size: 1.1rem;
}

.help-item p {
    color: #666;
    line-height: 1.6;
    margin: 0;
}

.help-section:last-child {
    text-align: center;
    padding: 20px;
}

.help-section a {
    color: #0078af;
    text-decoration: none;
}

.help-section a:hover {
    text-decoration: underline;
}

/* Contact Section */
.contact-info {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
}

/* Responsive Design */
@media (max-width: 768px) {
    .help-container {
        padding: 1rem;
    }

    .help-container h1 {
        font-size: 1.8rem;
    }

    .help-section {
        padding: 1.2rem;
    }
}

/* Training Sections */
.training-sections {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 15px;
}

.training-section {
    border: 1px solid #eee;
    border-radius: 6px;
    overflow: hidden;
}

.training-header {
    padding: 15px;
    margin: 0;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f8f9fa;
    transition: background-color 0.3s ease;
}

.training-header:hover {
    background: #e9ecef;
}

.training-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    padding: 0 15px;
}

.training-content.expanded {
    max-height: 1000px;
    padding: 15px;
}

.training-image {
    max-width: 100%;
    height: auto;
    margin-top: 15px;
    border-radius: 4px;
}