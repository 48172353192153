* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body,
html {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    width: 100vw;
    height: 100%;
}

/* Re-add the fadeIn animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Base styles for all pages */
.page-content,
.product-content,
.homepage-content {
    margin: 0;
    padding: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
}

/* Homepage-specific animations */
.homepage-content {
    opacity: 0;
    visibility: hidden;
}

.homepage-content.loaded {
    visibility: visible;
    animation: fadeIn 0.6s ease-out forwards;
}

/* Product page specific styles */
.product-content {
    opacity: 1;
    visibility: visible;
}

/* Ensure sections are visible on product page */
.product-content .video-section,
.product-content .pricing-section {
    opacity: 1;
    transform: none;
    visibility: visible;
    transition: none;
}

/* Keep homepage animations */
.homepage-content .video-section,
.homepage-content .pricing-section {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.homepage-content.loaded .video-section {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.4s;
}

.homepage-content.loaded .pricing-section {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.5s;
}

:root {
    --navbar-height: 80px;
}

.homepage-hero {
    position: relative;
    margin-top: calc(-1 * var(--navbar-height));
    padding-top: var(--navbar-height);
    min-height: 100vh;
    width: 100vw;
    background: url('../../public/sunset_lake.jpeg') center/cover no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 2rem;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.loaded .homepage-hero {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.1s;
}

.homepage-hero-content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 8rem;
    margin-bottom: 2rem;
}

.hero-extra-content {
    text-align: center;
    margin-top: -7rem;
    padding: 2rem;
    width: 100%;
}

.process-squares {
    position: relative;
    display: flex;
    justify-content: space-between;
    gap: 3rem;
    margin: 0 auto;
    max-width: 1000px;
    padding: 0 4rem;
}

.process-square {
    position: relative;
    width: 180px;
    height: 180px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    cursor: pointer;
}

.process-icon {
    width: 100%;
    height: auto;
    max-height: 80%;
    object-fit: contain;
    margin-bottom: 0.5rem;
}

.process-square span {
    color: #65a8d2;
    font-size: 1.2rem;
    font-weight: 500;
    opacity: 0.9;
}

.process-square:hover {
    transform: translateY(-5px);
    background: rgba(255, 255, 255, 0.15);
    border-color: rgba(255, 255, 255, 0.3);
}

@media (max-width: 768px) {
    .process-squares {
        flex-direction: column;
        align-items: center;
        gap: 2rem;
    }

    .process-square:not(:last-child)::after {
        display: none;
    }
}

/* Update connecting lines */
.process-square:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 135%;
    width: 3rem;
    height: 2px;
    background: rgba(255, 255, 255, 0.3);
    transform: translateY(-50%);
}

.homepage-hero-left {
    flex: none;
    width: 50%;
    color: #ffffff;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 2rem;
    margin-top: -2rem;
}

.homepage-hero-left h1 {
    font-size: 3.2rem;
    margin-bottom: 1rem;
    font-weight: bold;
    text-align: center;
    white-space: normal;
    color: #ffffff;
    max-width: 100%;
    padding: 0 1rem;
}

.homepage-hero-left p {
    font-size: 1.4rem;
    line-height: 1.6;
    text-align: center;
    margin-bottom: 1rem;
    color: #ffffff;
}

.homepage-hero-right {
    position: absolute;
    bottom: 6rem;
    right: 9rem;
    width: auto;
}

.homepage-hero-right h2 {
    font-size: 2.5rem;
    color: #ffffff;
    text-align: center;
    font-weight: 300;
}

.homepage-hero-logo {
    max-width: 5%;
    height: auto;
    margin: 1rem auto;
    margin-top: 0rem;
    display: block;
}


.hero-cta-button {
    background: rgba(18, 193, 251, 0.907);
    color: #ffffff;
    border: none;
    padding: 1rem 2.5rem;
    border-radius: 10px;
    font-size: 1.4rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    margin: 4rem auto 0.5rem;
    display: block;
    width: fit-content;
}

.hero-cta-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
    background: #04a7e7;
}

.hero-cta-button:active {
    transform: translateY(0);
}

@media (max-width: 768px) {
    .hero-cta-button {
        font-size: 1rem;
        padding: 0.8rem 2rem;
        margin: 2rem auto 0.5rem;
        width: auto;
    }

    .homepage-hero-left {
        width: 100%;
        text-align: center;
        align-items: center;
        padding: 2rem;
    }

    .hero-cta-button {
        font-size: 1rem;
        padding: 0.8rem 2rem;
        margin: 2rem auto 0.5rem;
        width: auto;
    }

    .homepage-hero-left h1 {
        font-size: 2rem;
        padding: 0;
    }

    .homepage-hero-content {
        padding-top: 4rem;
        margin-bottom: 2rem;
    }

    .homepage-hero-right {
        right: 0;
        width: 100%;
        text-align: center;
        bottom: 2rem;
    }

    .homepage-hero-right h2 {
        font-size: 2rem;
    }
}

.homepage-hero-features {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    max-width: 400px;
}

.homepage-hero-feature {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 12px;
    padding: 1.2rem;
    text-align: center;
    color: white;
    transition: all 0.3s ease;
    cursor: pointer;
    height: 50px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.feature-arrow {
    color: rgba(255, 255, 255, 0.8);
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.5rem 0;
    transition: transform 0.3s ease;
}

.feature-arrow:hover {
    transform: translateY(2px);
    color: rgba(255, 255, 255, 1);
}

.homepage-hero-feature h3 {
    font-size: 1rem;
    margin: 0;
    transition: all 0.3s ease;
}

.homepage-hero-feature .feature-detail {
    font-size: 0.9rem;
    opacity: 0;
    max-height: 0;
    transition: all 0.3s ease;
    margin-top: 0.5rem;
    text-align: left;
    white-space: pre-line;
    padding-left: 1rem;
}

.homepage-hero-feature:hover {
    height: 180px;
    transform: translateY(-3px);
    background: rgba(255, 255, 255, 0.15);
}

.homepage-hero-feature:hover .feature-detail {
    opacity: 1;
    max-height: 150px;
}

@media (max-width: 768px) {
    .homepage-hero-features {
        width: 100%;
        padding: 0 1rem;
    }

    .homepage-hero-feature {
        height: 50px;
    }

    .homepage-hero-feature:hover {
        height: 200px;
    }
}

@media (max-width: 768px) {
    .homepage-hero-content {
        flex-direction: column;
        text-align: center;
        padding: 2rem;
    }

    .homepage-hero-left h1 {
        font-size: 2rem;
    }

    .homepage-hero-features {
        width: 100%;
        padding: 0 1rem;
    }

    .homepage-hero-feature {
        height: 50px;
    }

    .homepage-hero-feature:hover {
        height: 120px;
    }
}

.workflow-section {
    padding: 6rem 4rem;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.loaded .workflow-section {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.2s;
}

.workflow-header {
    text-align: center;
    margin-bottom: 4rem;
}

.workflow-header h2 {
    font-size: 2.5rem;
    color: #0090d7;
    margin-bottom: 1rem;
}

.workflow-header p {
    font-size: 1.25rem;
    color: #666;
}

.workflow-steps {
    display: grid;
    grid-template-columns: 1fr auto 1fr auto 1fr;
    gap: 2rem;
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 2rem;
    align-items: center;
}

.workflow-step {
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.workflow-image {
    width: 100%;
    height: auto;
    min-height: 300px;
    border-radius: 12px;
    margin: 0 auto 2rem;
    object-fit: contain;
    display: block;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2),
        0 0 20px rgba(0, 144, 215, 0.1);
    transition: box-shadow 0.3s ease;
    padding: 0.5rem;
}

.workflow-image:hover {
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.25),
        0 0 30px rgba(0, 144, 215, 0.2);
}

.workflow-step h3 {
    font-size: 1.5rem;
    color: #0090d7;
    margin-bottom: 1rem;
}

.workflow-step p {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
}

.workflow-arrow {
    color: #0090d7;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 768px) {
    .workflow-section {
        padding: 4rem 2rem;
    }

    .workflow-steps {
        grid-template-columns: 1fr;
        gap: 4rem;
    }

    .workflow-header h2 {
        font-size: 2rem;
    }

    .workflow-image {
        width: 100%;
        max-width: 300px;
    }

    .workflow-steps {
        grid-template-columns: 1fr;
    }

    .workflow-arrow {
        transform: rotate(90deg);
        margin: 1rem 0;
        align-self: center;
    }
}

.workflow-cta {
    text-align: center;
    margin-top: 4rem;
    width: 100%;
    display: flex;
    justify-content: center;
}

.workflow-cta-button {
    background: rgba(18, 193, 251, 0.907);
    color: #ffffff;
    border: none;
    padding: 1rem 2.5rem;
    border-radius: 10px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    margin: 6rem auto 0;
    display: block;
}

.workflow-cta-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
    background: #04a7e7;
}

.workflow-cta-button:active {
    transform: translateY(0);
}

@media (max-width: 768px) {
    .workflow-cta-button {
        font-size: 1rem;
        padding: 0.8rem 2rem;
        margin: 4rem auto 0;
        margin-bottom: 2rem;
    }
}

.cta-button {
    display: none;
}

.video-section {
    padding: 3rem 4rem;
    background: #f8f9fa;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.loaded .video-section {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.4s;
}

.video-header {
    text-align: center;
    margin-bottom: 2rem;
}

.video-header h2 {
    font-size: 2.5rem;
    color: #0090d7;
    margin-bottom: 1rem;
}

.video-container {
    max-width: 720px;
    margin: 0 auto;
    position: relative;
    padding-bottom: 28.125%;
    height: 0;
    overflow: hidden;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
    .video-section {
        padding: 2rem;
    }

    .video-header h2 {
        font-size: 2rem;
    }

    .video-container {
        padding-bottom: 56.25%;
    }
}

.pricing-section {
    padding: 6rem 4rem;
    background: #fff;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.loaded .pricing-section {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.5s;
}

.pricing-section .pricing-header {
    text-align: center;
    margin-bottom: 4rem;
}

.pricing-section .pricing-header h2 {
    font-size: 2.5rem;
    color: #0090d7;
    margin-bottom: 1rem;
}

.pricing-section .pricing-header p {
    font-size: 1.25rem;
    color: #666;
}

.pricing-section .pricing-container {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    max-width: 1400px;
    margin: 0 auto;
}

.pricing-card {
    flex: 1;
    min-width: 250px;
    max-width: 300px;
}


@media (max-width: 1200px) {
    .pricing-section .pricing-container {
        flex-wrap: wrap;
    }

    .pricing-card {
        flex: 1 1 calc(50% - 1.5rem);
    }
}

@media (max-width: 768px) {
    .pricing-card {
        flex: 1 1 100%;
        max-width: 100%;
    }
}

.features-items {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    max-width: 400px;
}

.feature-arrow {
    color: rgba(255, 255, 255, 0.8);
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -1px;
    margin-bottom: -1px;
}

.homepage-hero-feature {
    height: 50px;
}

.homepage-hero-feature:hover {
    height: 120px;
}

@media (max-width: 768px) {
    .features-container {
        flex-direction: column;
        align-items: center;
        gap: 2rem;
    }

    .features-items {
        width: 100%;
    }
}

.desktop-preview {
    width: 100%;
    max-width: 650px;
    height: auto;
    margin-bottom: 2rem;
    border-radius: 8px;
    background: transparent;
    box-shadow: none;
}

@media (max-width: 1440px) {
    .desktop-preview {
        max-width: 450px;
        /* Smaller size for 13" laptops */
    }
}

@media (max-width: 1200px) {
    .desktop-preview {
        max-width: 400px;
    }
}

@media (max-width: 992px) {
    .desktop-preview {
        max-width: 350px;
    }
}

@media (max-width: 768px) {
    .desktop-preview {
        max-width: 300px;
        margin-bottom: 1.5rem;
    }
}

@media (max-width: 480px) {
    .desktop-preview {
        max-width: 250px;
    }
}

.no-card-text {
    font-size: 0.9rem;
    line-height: 1.4;
    text-align: center;
    color: #65a8d2;
    opacity: 0.9;
    margin: 0;
}

.homepage-hero-left .no-card-text {
    font-size: 0.9rem;
    line-height: 1.4;
    text-align: center;
    color: #ffffff;
    opacity: 0.9;
    margin: 0;
}

@media (max-width: 768px) {
    .homepage-hero-left {
        padding-left: 2rem;
    }
}

/* Make the middle image (Generate) smaller */
.workflow-step:nth-child(3) .workflow-image {
    min-height: 150px;
    max-width: 150px;
}

/* Make the first and last images larger */
.workflow-step:first-child .workflow-image,
.workflow-step:last-child .workflow-image {
    min-height: 400px;
    max-width: 500px;
}

@media (max-width: 1200px) {

    .workflow-step:first-child .workflow-image,
    .workflow-step:last-child .workflow-image {
        min-height: 300px;
        max-width: 400px;
    }

    .workflow-step:nth-child(3) .workflow-image {
        min-height: 120px;
        max-width: 120px;
    }
}

.quickmed-section {
    padding: 6rem 4rem;
    background: #ffffff;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.loaded .quickmed-section {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.3s;
}

@media (max-width: 768px) {
    .quickmed-section {
        padding: 2rem 1rem;
    }
}

.quickmed-header {
    text-align: center;
    margin-bottom: 2rem;
    margin-top: -3rem;
}

.quickmed-header h2 {
    font-size: 2.8rem;
    color: #0090d7;
    margin-bottom: 1rem;
}

.quickmed-header p {
    font-size: 1.2rem;
    color: #666;
    max-width: 800px;
    margin: 0 auto;

}

.quickmed-subheader {
    font-size: 2rem;
    color: #0882d3;
    text-align: center;
    margin-bottom: 4rem;
}

.quickmed-content {
    display: flex;
    gap: 8rem;
    max-width: 1800px;
    margin: 0 auto;
    align-items: center;
    padding: 0 2rem;
    margin-top: 4rem;
}

.quickmed-image {
    flex: 0 0 60%;
}

.quickmed-preview {
    width: 100%;
    height: auto;
    border-radius: 12px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
}

.quickmed-features {
    flex: 0 0 30%;
}

.quickmed-features ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.quickmed-features li {
    background: #fff;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.04);
    transition: transform 0.3s ease;
}

.feature-title {
    font-size: 1.1rem;
    font-weight: 600;
    color: #0090d7;
    margin-bottom: 0.4rem;
    display: block;
}

.quickmed-features li p {
    font-size: 0.9rem;
    color: #555;
    line-height: 1.4;
    margin: 0;
}

@media (max-width: 1024px) {
    .quickmed-content {
        flex-direction: column;
        gap: 4rem;
    }

    .quickmed-image {
        flex: 0 0 100%;
    }

    .quickmed-features {
        flex: 0 0 100%;
    }
}

.loading-container {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
}

.loading-spinner {
    width: 50px;
    height: 50px;
    border: 3px solid #f3f3f3;
    border-top: 3px solid #0090d7;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media (max-width: 768px) {
    .homepage-hero-content {
        flex-direction: column;
        text-align: center;
        padding: 2rem;
    }

    .homepage-hero-left h1 {
        font-size: 2rem;
    }

    .hero-cta-button {
        font-size: 1rem;
        padding: 0.8rem 2rem;
    }

    .process-squares {
        flex-direction: column;
        align-items: center;
        gap: 2rem;
    }

    .workflow-steps {
        grid-template-columns: 1fr;
        gap: 4rem;
    }

    .workflow-header h2 {
        font-size: 2rem;
    }

    .workflow-image {
        width: 100%;
        max-width: 300px;
    }

    .workflow-arrow {
        transform: rotate(90deg);
        margin: 1rem 0;
    }

    .pricing-card {
        flex: 1 1 100%;
        max-width: 100%;
    }

    .desktop-preview {
        max-width: 300px;
        margin-bottom: 1.5rem;
    }

    .quickmed-content {
        flex-direction: column;
        gap: 4rem;
    }

    .quickmed-image,
    .quickmed-features {
        flex: 0 0 100%;
    }

    .quickmed-preview {
        width: 100%;
        max-width: 100%;
        height: auto;
        margin: 0 auto;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .homepage-hero-content {
        padding: 3rem;
    }

    .workflow-steps {
        grid-template-columns: 1fr auto 1fr;
        gap: 2rem;
    }

    .pricing-card {
        flex: 1 1 calc(50% - 1.5rem);
    }

    .desktop-preview {
        max-width: 400px;
    }

    .quickmed-content {
        gap: 6rem;
    }
}

@media (max-width: 480px) {
    .homepage-hero {
        padding-top: calc(var(--navbar-height) + 5rem);
    }

    .homepage-hero-left {
        padding-top: 0;
    }
}

@media (max-width: 768px) {
    .quickmed-preview {
        width: 100%;
        max-width: 100%;
        height: auto;
        margin: 0 auto;
    }
}

.homepage-loading-container {
    /* Existing styles */
}

.homepage-loading-spinner {
    /* Existing styles */
}

/* Update the base section styles for homepage only */
.homepage-content .homepage-hero,
.homepage-content .workflow-section,
.homepage-content .quickmed-section,
.homepage-content .video-section,
.homepage-content .pricing-section {
    visibility: hidden;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out, visibility 0s linear 0.5s;
}

/* Homepage loaded states */
.homepage-content.loaded .homepage-hero {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.1s;
}

.homepage-content.loaded .workflow-section {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.2s;
}

.homepage-content.loaded .quickmed-section {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.3s;
}

.homepage-content.loaded .video-section {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.4s;
}

.homepage-content.loaded .pricing-section {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.5s;
}

.enterprise-section {
    text-align: center;
    margin-top: 4rem;
    padding: 2rem;
    background: #f8f9fa;
    border-radius: 12px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.enterprise-section h3 {
    color: #0090d7;
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.enterprise-section p {
    color: #666;
    font-size: 1.1rem;
}

.enterprise-section a {
    color: #0090d7;
    text-decoration: none;
    font-weight: 500;
    transition: color 0.2s ease;
}

.enterprise-section a:hover {
    color: #04a7e7;
    text-decoration: underline;
}

@media (max-width: 768px) {
    .enterprise-section {
        margin: 3rem 0.5rem;
        padding: 1.5rem;
        max-width: calc(100% - 1rem);
    }

    .enterprise-section h3 {
        font-size: 1.1rem;
        padding: 0 0.5rem;
        line-height: 1.4;
    }

    .enterprise-section p {
        font-size: 0.95rem;
        padding: 0 0.5rem;
        line-height: 1.4;
    }
}

.events-section {
    padding: 4rem;
    background: #f8f9fa;
    margin-bottom: 2rem;
}

.events-header {
    text-align: center;
    margin-bottom: 3rem;
}

.events-header h2 {
    font-size: 2.5rem;
    color: #0090d7;
    margin-bottom: 1rem;
}

.events-header p {
    font-size: 1.25rem;
    color: #666;
    margin-bottom: 2rem;
}

.events-list {
    max-width: 600px;
    margin: 0 auto;
    list-style: none;
    padding: 0;
}

.event-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.event-item:last-child {
    border-bottom: none;
}

.event-info {
    color: #333;
    font-size: 1.1rem;
}

.event-link {
    color: #0090d7;
    text-decoration: none;
    font-weight: 500;
    transition: all 0.3s ease;
}

.event-link:hover {
    color: #04a7e7;
    text-decoration: underline;
}

@media (max-width: 768px) {
    .events-section {
        padding: 2rem;
    }

    .events-header h2 {
        font-size: 2rem;
    }

    .event-item {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
        padding: 1rem;
    }
}

.pricing-footer {
    display: flex;
    justify-content: center;
    width: 100%;
}

.subscribe-button {
    margin: 0 auto;
    width: fit-content;
}

/* Add specificity to override Profile.css styles */
.homepage-content .enterprise-section h3 {
    color: #0090d7;
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

@media (max-width: 768px) {
    .homepage-content .enterprise-section h3 {
        font-size: 1rem;
        padding: 0 0.5rem;
        line-height: 1.4;
    }
}